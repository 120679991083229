import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { ScreenSize } from "helper/ScreenSize";

const FrontpageAll = ({ listOfId, refreshApi, loading, navne }) => {
  const [myData, setMyData] = useState([]);
  //const [loaded, setLoaded] = useState(false);
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }
    if (refreshApi) {
      console.log(listOfId);
      apiFacade
        .getData("/bookings/getbookings/" + listOfId.join())
        .then((data) => {
          setMyData(data);
        })
        .finally();
    }
  }, [refreshApi]);

  if (myData) {
    if (myData.length > 0) {
      let pageNr = myData.length - 1;
      var doc = new jsPDF("l", "pt", "a4");

      myData.map((data, i) => {
        console.log(data);
        doc.setFontSize(14);
        doc.setFontType("normal");
        doc.text(`${data.region}`, 770, 20);
        // ID
        doc.setFontSize(70);
        doc.setFontType("bold");
        doc.text(
          `ID: ` + `${data.id}`,
          doc.internal.pageSize.width / 2,
          80,
          null,
          null,
          "center"
        );

        // Job Type
        let rectColorLength = 0;
        doc.setFontType("bold");
        doc.setFontSize(24);
        if (data.bookinginfo.jobType === "Fri bar arrangement") {
          doc.setFillColor(134, 219, 94);
          rectColorLength = 10;
        } else if (data.bookinginfo.jobType === "Aftalt antal cocktails") {
          doc.setFillColor(255, 210, 67);
          rectColorLength = -20;
        } else if (data.bookinginfo.jobType === "Graffiticocktails") {
          doc.setFillColor(255, 100, 100);
          rectColorLength = -13;
        } else if (data.bookinginfo.jobType === "Cocktailkursus") {
          doc.setFillColor(110, 192, 255);
          rectColorLength = 12;
        } else {
          doc.setFillColor(254, 254, 254);
        }
        doc.rect(
          0,
          58,
          data.bookinginfo.jobType.length * 11.6 + rectColorLength,
          30,
          "F"
        );
        doc.text(`${data.bookinginfo.jobType}`, 0, 80);

        // jobsImportance

        doc.setFillColor("#" + data.jobsImportanceColor);
        doc.rect(
          0,
          99,
          data.jobsImportance.length * 8.9 + rectColorLength,
          30,
          "F"
        );
        doc.setFontSize(16);
        doc.text(`${data.jobsImportance}`, 10, 120);

        //Date
        const moment = require("moment");
        var date = moment(`${data.date}`, "YYYY/MM/DD");
        doc.setFontSize(42);
        doc.setFontType("boold");
        doc.text(
          `Dato: ${date.format("DD/MM/YYYY")}`,
          doc.internal.pageSize.width / 2,
          127,
          null,
          null,
          "center"
        );

        // Start && Slut
        doc.setLineWidth(2);
        doc.line(
          doc.internal.pageSize.width / 2 + 3,
          143,
          doc.internal.pageSize.width / 2 + 3,
          163
        );
        var startTime = moment(`${data.date}`);
        var endTime = moment(`${data.dateEnd}`);
        doc.setFontSize(24);
        doc.setFontType("boold");
        doc.text(
          `Start: ${startTime.format("HH:mm  ")}Slut: ${endTime.format(
            "HH:mm"
          )}`,
          doc.internal.pageSize.width / 2,
          160,
          null,
          null,
          "center"
        );

        // if its company
        if (data.companyName !== "") {
          doc.setFontSize(28);
          doc.setFontType("boold");
          doc.text(
            `Firma: ${data.companyName}`,
            doc.internal.pageSize.width / 2,
            200,
            null,
            null,
            "center"
          );
        }
        // if not then --> privat
        else {
          doc.setFontSize(28);
          doc.setFontType("boold");
          doc.text(
            `Privat: ${data.contactPerson}`,
            doc.internal.pageSize.width / 2,
            200,
            null,
            null,
            "center"
          );
        }

        // Adresse
        doc.setFontSize(28);
        doc.setFontType("boold");
        if (data.address != null) {
          doc.text(
            `Address: ${data.address}`,
            doc.internal.pageSize.width / 2,
            240,
            null,
            null,
            "center"
          );
        } else {
          doc.text(
            `Address: kontakt sælger eller ${data.contactPerson}`,
            doc.internal.pageSize.width / 2,
            240,
            null,
            null,
            "center"
          );
        }

        // kontakt person samt tlf. nr
        doc.setFontSize(18);
        doc.setFontType("boold");
        doc.text(
          `Kontaktperson: ${data.contactPerson + " |"} Tlf: ${data.phone}`,
          doc.internal.pageSize.width / 2,
          280,
          null,
          null,
          "center"
        );

        //Task
        var pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
          doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        if (data.tasks != null) {
          var arryText = [];
          data.tasks.map((m) => {
            arryText.push(m.text);
          });

          if (arryText.length > 12) {
            doc.setFontSize(6);

            doc.text(
              "Tasks: " + arryText.join(" & "),
              pageWidth / 2,
              pageHeight - 10,
              "center"
            );
          } else {
            doc.setFontSize(12);

            doc.text(
              "Tasks: " + arryText.join(" & "),
              pageWidth / 2,
              pageHeight - 10,
              "center"
            );
          }
        }

        var bookingInfoHeadRow = [
          { header: "Title", dataKey: "title" },
          { header: "Info", dataKey: "value" },
        ];
        var LoadingListInfoHeadRow = [
          { header: "Title", dataKey: "title" },
          { header: "Check", dataKey: "value" },
        ];

        // horizontal line

        // info
        doc.setFontSize(18);
        doc.setFontType("bold");
        doc.text(
          `Info`,
          doc.internal.pageSize.width / 2 - 260,
          315,
          null,
          null,
          "center"
        );
        var bookinginfo = [];

        bookinginfo.push({
          title: "Job type",
          value: data.bookinginfo.jobType,
        });
        bookinginfo.push({
          title: "Menu type",
          value: data.bookinginfo.menutype,
        });

        if (data.whiteLabel) {
          bookinginfo.push({
            title: "White label",
            value: "Yes",
          });
        } else {
          bookinginfo.push({
            title: "White label",
            value: "Not selected",
          });
        }

        bookinginfo.push({
          title: "Persons",
          value: data.bookinginfo.persons,
        });

        bookinginfo.push({
          title: "Barelementer",
          value: data.barModules + " " + data.bartype,
        });

        bookinginfo.push({
          title: "Bartenders",
          value: data.bookinginfo.bartenders,
        });

        // if (data.Skjorte === 0) {
        //   bookinginfo.push({
        //     title: "Skjorte",
        //     value: "Ingen tilvalgt",
        //   });
        // } else {
        //   bookinginfo.push({
        //     title: "Skjorte",
        //     value: data.bookinginfo.skjorte,
        //   });
        // }

        // if (data.barbacks === 0) {
        //   bookinginfo.push({
        //     title: "Barbacks",
        //     value: "None",
        //   });
        // } else {
        //   bookinginfo.push({
        //     title: "Barbacks",
        //     value: data.bookinginfo.barbacks,
        //   });
        // }

        bookinginfo.push({
          title: "Timer",
          value: data.duration,
        });

        let glass = [];

        data.newGlassList.map((d) => {
          glass.push({
            title: d.name,
            value: d.amount,
          });
        });
        let loadingList = [
          {
            title: "Bar + Cover",
            value: "",
          },
          {
            title: "Sorte skraldespande",
            value: "",
          },
          {
            title: "Is",
            value: "",
          },
          {
            title: "Glas",
            value: "",
          },
          {
            title: "Mappen",
            value: "",
          },
          {
            title: "Spildspand",
            value: "",
          },
          {
            title: "Grønt",
            value: "",
          },
        ];

        // glas
        doc.setFontSize(18);
        doc.setFontType("bold");
        doc.text(
          `Glas`,
          doc.internal.pageSize.width / 2,
          315,
          null,
          null,
          "center"
        );

        // is
        let isterninger = [];

        data.isterninger.map((d) => {
          isterninger.push({
            title: d.isterning,
            value: d.amount,
          });
        });

        doc.setFontSize(18);
        doc.setFontType("bold");
        doc.text(
          `Is`,
          doc.internal.pageSize.width / 2 + 265,
          300,
          null,
          null,
          "center"
        );

        doc.setFontSize(18);
        doc.setFontType("bold");
        doc.text(
          `Loading List`,
          doc.internal.pageSize.width / 2 + 265,
          380,
          null,
          null,
          "center"
        );

        doc.autoTable({
          columns: bookingInfoHeadRow,
          body: isterninger,
          startY: 304,
          tableWidth: 240,
          margin: { horizontal: 565 },

          theme: "grid",

          styles: {
            lineColor: [44, 62, 80],
            lineWidth: 1,
          },

          columnStyles: {
            title: { cellWidth: 120 },
            value: { cellWidth: 120 },
          },
          headStyles: { fillColor: hexToRgb("#595959") },
        });

        doc.autoTable({
          columns: bookingInfoHeadRow,
          body: bookinginfo,
          startY: 330,
          tableWidth: 240,

          theme: "grid",

          styles: {
            lineColor: [44, 62, 80],
            lineWidth: 1,
          },

          headStyles: { fillColor: hexToRgb("#595959") },
        });

        doc.autoTable({
          columns: bookingInfoHeadRow,
          body: glass,
          startY: 330,
          tableWidth: 240,
          margin: { horizontal: 300 },
          styles: { overflow: "linebreak" },

          theme: "grid",

          styles: {
            lineColor: [44, 62, 80],
            lineWidth: 1,
          },

          columnStyles: {
            title: { cellWidth: 120 },
            value: { cellWidth: 120 },
          },
          headStyles: { fillColor: hexToRgb("#595959") },
        });

        doc.autoTable({
          columns: LoadingListInfoHeadRow,
          body: loadingList,
          startY: 383,
          tableWidth: 240,
          margin: { horizontal: 565 },

          theme: "grid",

          styles: {
            lineColor: [44, 62, 80],
            lineWidth: 1,
          },

          columnStyles: {
            title: { cellWidth: 120 },
            value: { cellWidth: 120 },
          },
          headStyles: { fillColor: hexToRgb("#595959") },
        });

        // Grønt
        // doc.setFillColor(134, 219, 94);
        // doc.rect(doc.internal.pageSize.width / 2 + 144, 541, 240, 25, "FD");
        // doc.setFontType("normal");
        // doc.setFontSize(18);
        // doc.text(`Grønt`, doc.internal.pageSize.width / 2 + 175, 556);

        doc.rect(730, 410, 10, 10);
        doc.rect(730, 432, 10, 10);
        doc.rect(730, 455, 10, 10);
        doc.rect(730, 475, 10, 10);
        doc.rect(730, 497, 10, 10);
        doc.rect(730, 515, 10, 10);
        doc.rect(730, 535, 10, 10);
        doc.setFillColor(255, 255, 255);

        if (i < pageNr) {
          doc.addPage();
        }
      });
      const uri = doc.output("datauristring");
      return (
        <iframe
          title="myFrame"
          frameBorder="0"
          width={pdfWidth}
          height={pdfHeight}
          src={uri}
        />
      );
    }
  }
  if (refreshApi) {
    if (!myData) {
      return <div>Ingen Booking, vælg ny region eller ny dato range</div>;
    } else {
      return (
        <div>
          <div className="sweet-loading" style={{ marginLeft: 300 }}>
            <BarLoader
              height={5}
              width={200}
              sizeUnit={"px"}
              size={500}
              color={"#4C8FBF"}
            />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default FrontpageAll;
